@media (max-width: 768px) {
  .carousel-item {
    min-height: 20vh !important;
    background-size: cover;
  }
}

@media (min-width: 768px) {
  .carousel-item {
    min-height: 82vh;
    background-size: cover;
  }
}
