@media (max-width: 768px) {
    .carousel-item {
      min-height: 30vh;
    }
}

@media (min-width: 768px) {
    .carousel-item {
        min-height: 84vh;
    }
  }
@media (max-width: 768px) {
    .title {
      min-height: 30vh;
    }
}

@media (min-width: 768px) {
    .title {
        min-height: 84vh;
    }
  }