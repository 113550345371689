/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 8px; 
}

::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px; 
}

::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
    background: #555; 
}
